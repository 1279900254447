'use strict';

var stickykit = require('./../../stickykit'),
  productStoreInventory = require('./../../../../../app_storefront_core/cartridge/js/storeinventory/product'),
  variant = require('./variant'),
  btcRates = require('./btcRates'),
  colorSelection = require('./colorselection'),
  sizeGuide = require('./sizeGuide'),
  availability = require('./../../../../../app_storefront_core/cartridge/js/pages/product/availability'),
  productNav = require('./productNav'),
  productSet = require('./productSet'),
  util = require('./../../util'),
  productTile = require('./../../product-tile'),
  $cache = {},
  options = {
    'showNavigation': false,
    'stickykit': {
      'container': '.b-pdp-product_info',
      'options': {
        'parent': '.b-pdp-product_view',
        'offset_top': function() {
          return $('.js-sticky_header-wrapper').height();
        },
        'bottoming': true
      }
    }
  },
  orderDuties = require('./../../util/order-duties');

/**
 * @private
 * @function
 * @description Initializes parameters for component
 */
function initializeParams(params) {
  options = $.extend(true, {}, options, params);
}

/**
 * @private
 * @function
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom() {

  if (options.showNavigation) {
    productNav();
  }
  variant.init();
  btcRates.addCryptoPrice();
  colorSelection.init();
  availability();
  productSet.init();
  sizeGuide.init();

  if (SitePreferences.STORE_PICKUP) {
    productStoreInventory.init();
  }

  if (!util.isMobile()) {
    $(document).on('product.imagesloaded window.resize layout.reload', function(e) {
      stickykit.init(options.stickykit, true);
    });
  }
  
  // Force dispatch recommendations event second time (for both tabbed or non-tabbed versions)
  setTimeout(function(){
    var einsteinContainer = document.querySelector('.js-einstein-recommendations [id^="cq_recomm_slot"]');
    if (einsteinContainer && einsteinContainer.children.length > 0) {
      document.dispatchEvent(new CustomEvent("recommendations.loaded"));
    }
  }, 4000);
  
}

/**
 * @private
 * @function
 * @description Initializes cache for component
 */
function initializeCache() {
  $cache.pdpMain = $('#pdpMain');
  $cache.productInfo = $('.product-info');
  $cache.productRecomm = $('.js-pdp-recommendations');
}

/**
 * @private
 * @function
 * @description Initialize event handlers on product detail page
 */
function initializeEvents() {
  $cache.pdpMain
    // Add to Wishlist and Add to Gift Registry links behaviors
    .on('click', '[data-action="wishlist"], [data-action="gift-registry"]', function() {
      var data = util.getQueryStringParams($('.pdpForm').serialize());
      if (data.cartAction) {
        delete data.cartAction;
      }
      var url = util.appendParamsToUrl(this.href, data);
      this.setAttribute('href', url);
    })

    // product options
    .on('change', '.product-options select', function() {
      var salesPrice = $pdpMain.find('.product-add-to-cart .price-sales');
      var selectedItem = $(this).children().filter(':selected').first();
      salesPrice.text(selectedItem.data('combined'));
    })

    // prevent default behavior of thumbnail link and add this Button
    .on('click', '.thumbnail-link, .unselectable a', function(e) {
      e.preventDefault();
    });

  //Init productTile for Predictive recommendations
  document.addEventListener('recommendations.loaded', function() {
    productTile.init();
  });

  if ($cache.pdpMain && $cache.productInfo) {
    if ($cache.productRecomm.hasClass("js-tabs-container")) {
      initRecommTabs();
    } else {
      initRecomm();
    }
  }
}

// Toggle of PDP Recommendations and "Last visited" tabs when "enablePdpRecommTabs" is enabled
function initRecommTabs() {
  // Init tabs for recommendations and last visited; jQuery UI tabs aren't used due to a page self-inclusion bug
  let btnActiveClass = 'js-tab-button--active';
  let tabActiveClass = 'js-tab-content--active';
  $('.js-tab-button').click(function(e) {
    e.preventDefault();
    let targetTab = $(this).attr('href');
    $(this).siblings().removeClass(btnActiveClass);
    $(this).addClass(btnActiveClass);
    $(targetTab).siblings().removeClass(tabActiveClass);
    $(targetTab).addClass(tabActiveClass);
  });

  // Init owl carousel and show hidden buttons set when Einstein recommendations 
  // have been loaded; there can be only one carousel with dynamic recommendations
  document.addEventListener('recommendations.loaded', function() {
    $('.js-tab-buttons').animate({opacity: 1}, 200).css({'height': 'auto'});
    $('.js-einstein-title').fadeIn(200);
    $('.owl-carousel').on('initialized.owl.carousel', function() {
      // Make recomms button & tab active
      $('.js-tab-button-visited').removeClass(btnActiveClass);
      $('.js-tab-button-recomm').addClass(btnActiveClass).show().animate({opacity: 1}, 200);
      
      $('.js-last-visited').removeClass(tabActiveClass);
      $('.js-einstein-recommendations').addClass(tabActiveClass);
    });
  });

}

// Show hidden title when native SF recommendations have been loaded
function initRecomm() {
  document.addEventListener('recommendations.loaded', function() {
    $('.js-einstein-title').fadeIn(200);
  });
}

module.exports = {
  init: function(params) {
    initializeParams(params);
    initializeDom();
    initializeCache();
    initializeEvents();
    orderDuties.init();
  }
};
