// Cloudinary lib is included in producttopcontent.isml template from
// app_storefront_core_ext/cartridge/templates/default/product/components/productvideoincludes.isml

/**
 * Get video config json from OrganizationPreferences (Global Preferences)
 */
function getCloudinaryVideoConfig() {
  let config = {};
  let defaultConfig = {
    cloud_name: "dqyv1rzlf",
    autoplay: true,
    loop: true,
    transformation: {
      quality: "auto",
      fetch_format: "auto"
    }
  };
  try {
    let configJSON = SitePreferences.CLOUDINARY_VIDEO_CONFIG || defaultConfig;
    config = JSON.parse(configJSON);
  } catch (e) {
    // Set a default minimal config
    config = defaultConfig;
  }
  return config;
}

let cldPlayers,
  cldPlayerClass = "video.cld-video-player",// tag name makes selection more accurate
  cldPlayerOptions = getCloudinaryVideoConfig();

/**
 * @private
 * @function
 * @description Initializes events for PDP if cloudinary exists in window
 */
function initializeEvents() {
  if (window.hasOwnProperty('cloudinary')) {
    initCldPlayer();
  }
}

function initCldPlayer() {
  const initPlayer = () => {
    cldPlayers = cloudinary.videoPlayers(cldPlayerClass, cldPlayerOptions);
    cldPlayers.forEach(el => {
      el.on('posterchange', function(e) {
        const poster = new Image();
        poster.src = e.Player.videojs.poster_;

        poster.onerror = function() {
          hideBrokenVideoContainers();
        };
      });

      el.on('loadstart', function(e) {
        const poster = e.Player.videojs.poster_;

        if (!poster) {
          hideBrokenVideoContainers();
        }
      });

      el.on('error', function(e) {
        if (e.Player.videojs.error_) {
          el.dispose();
          console.info(e.Player.videojs.error_);

          hideBrokenVideoContainers();
        }
      });
    });
  };

  function hideBrokenVideoContainers() {
    let videoContainers = document.querySelectorAll('.m-pdp-video-item');
    if (videoContainers.length) {
      videoContainers.forEach(item => {
        let parentSlide = item.closest('.owl-item');
        if (parentSlide){
          parentSlide.setAttribute('style', 'display:none !important');
          parentSlide.remove();
        } else {
          item.setAttribute('style', 'display:none !important');
          item.remove();
        }
      });
    }
  }

  initPlayer();

  $(document).on('product.variation.changed', function() {
    // Destroy previously initialized players to init them again after size/color change
    if (cldPlayers) {
      cldPlayers.forEach(function(el) {
        el.dispose();
      });
    }
    // Create new players after images are ready
    $(document).on('product.imagesloaded', function() {
      initPlayer();
    });
  });

}


/* @module productVideo
 * @description This module handles Cloudinary video player on PDP
 **/
module.exports = {
  init: function() {
    initializeEvents();
  }
};
