'use strict';
var ajax = require('./../../../../../app_storefront_core/cartridge/js/ajax'),
	util = require('./../../util'),
	progress = require('./../../../../../app_storefront_core/cartridge/js/progress'),
	image = require('./image'),
	productStoreInventory = require('./../../../../../app_storefront_core/cartridge/js/storeinventory/product'),
	btcRates = require('./btcRates'),
	$cache = {},
	orderDuties = require('./../../util/order-duties');

/**
 * @private
 * @function
 * @description Initializes cache for component
 */
function initializeCache(container) {
	$cache.variantContainer = $(container || '.js-pdp-product');
}


/**
 * @private
 * @function
 * @description Initializes events for component
 */
function initializeEvents() {
	// Size or color swatch click should trigger replacement of product content with a new variant
	$cache.variantContainer.on('click', '.js-swatchanchor', function(e) {
		e.preventDefault();
		let isQuickView = $(this).closest('#product-content').hasClass("js-product-content-quickview");
		let isColorChange = $(e.target).closest('.js-swatchanchor').attr('class').includes("color");

		if ($(this).parents('li').is('.unselectable, .selected')) {
			return;
		}

		updateContent(
			this.href,
			isQuickView ? $(this).closest('#QuickViewDialog #pdpMain') : $(this).closest('#pdpMain'),
			isQuickView ? $(this).closest('.js-product-content-quickview') : $(this).closest('.js-product-content-pdp'),
			$(this).closest('.js-pdp-attribute').index(),
			$(this).data('scroll') && !util.isMobile(),
			isQuickView,
			isColorChange
		);
	});

	// Product set
	$('.js-pdp-product_set').on('click', '.js-swatchanchor', function(e) {
		e.preventDefault();
		let isQuickView = $(this).closest('#product-content').hasClass("js-product-content-quickview");
		let isColorChange = $(e.target).closest('.js-swatchanchor').attr('class').includes("color");

		if ($(this).parents('li').is('.unselectable, .selected')) {
			return;
		}
		updateContent(
			Urls.getSetItem + this.search,
			isQuickView ? $(this).closest('#QuickViewDialog #pdpMain') : $(this).closest('#pdpMain'),
			$(this).closest('.js-product_set-item'),
			$(this).closest('.js-pdp-attribute').index(),
			isQuickView,
			isColorChange
		);
	});
	
	$(document).on('product.variation.changed', function(){
		updatePageContextSkuAndPid();
	});
}


/**
 * @private
 * @function
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} href - url of the new product variant
 **/
function updateContent(href, $pdpMain, $updateContainer, 
	preselectIndex, scrollToTop, isQuickView, isColorChange) {
	var $pdpForm = $updateContainer.find('.pdpForm'),
		qty = $pdpForm.find('input[name="Quantity"]').first().val(),
		params = {
			Quantity: isNaN(qty) ? '1' : qty,
			format: 'ajax',
			productlistid: $pdpForm.find('input[name="productlistid"]').first().val()
		};

	$pdpMain.addClass('js-loading');

	!!scrollToTop && !isQuickView ? util.scrollBrowser(0, 200, loadContent) : loadContent();

	function loadContent() {
		ajax.load({
			url: util.appendParamsToUrl(href, params),
			target: $updateContainer,
			callback: function() {
				if (SitePreferences.STORE_PICKUP) {
					productStoreInventory.init();
				}
				image.replaceImages(function () {
					$pdpMain.removeClass('js-loading');
				});

				$updateContainer.find('.js-add-variant-to-cart').removeAttr('disabled').attr('type', 'submit');
				$(document).trigger('product.variation.changed', {'container': $updateContainer});
				orderDuties.init();
				btcRates.addCryptoPrice();

			}
		});

		// Clear loader after a delay  if it's stuck
		setTimeout(function() {
			if ($pdpMain.hasClass('js-loading')) {
				$pdpMain.removeClass('js-loading');
			}
		}, 3000);
		
	}

	
	// Fire additional events to distinguish color & size variation changes
	if (isColorChange){
		$(document).trigger('product.color.changed', {'container': $updateContainer});
	} else {
		$(document).trigger('product.size.changed', {'container': $updateContainer});
	}

}


// Close the size toggle when clicking anywhere outside it
function closeSizeSelector() {
	$(".js-pdp-product").click(function(event) {
		let clickTarget = $(event.target);
		let sizeSelector = $(".attribute-size .b-swatches-value");
		let opened = sizeSelector.hasClass("expanded");
		if (opened === true && !clickTarget.hasClass("b-swatches-value")) {
			sizeSelector.click();
		}
	});
}

/**
 * @private
 * @function
 * @description Updates color & SKU in pageContext in addition to AnalyticsHelper.js
 **/
function updatePageContextSkuAndPid() {
	let pageContext = window.pageContext,
		addToCartBtn = $("#add-to-cart"),
		newColor = addToCartBtn ? addToCartBtn.data("variant") : pageContext.productColor,
		newSKU = addToCartBtn ? addToCartBtn.data("id") : pageContext.productID,
		pid = $("#pid") ? $("#pid").attr("value") : null,
		prodSize;

	pageContext.productColor = newColor ? newColor : pageContext.productColor;
	pageContext.productID = newSKU ? newSKU : pageContext.productID;
	if (pid !== null && pid !== newSKU) {
		prodSize = pid.split("_")[3] || "0";
		pageContext.productPID = pid;
		pageContext.productFBID = newSKU + "_" + prodSize;
	}
}

module.exports = {
	init: function(container) {
		initializeCache(container);
		initializeEvents();
	},
	closeSizeSelector: closeSizeSelector
};
