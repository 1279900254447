'use strict';

function initProductSnippet() {
	var snippet = window.pageContext.productSnippet ? JSON.stringify(window.pageContext.productSnippet) : null;

	if (snippet !== null) {
		var script = document.createElement('script');
		script.type = 'application/ld+json';
		script.text = snippet;
		$("body").append(script);
	}
}

module.exports = {
	init : function () {
		initProductSnippet();
	}
};
